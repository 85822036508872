#gallery{
  margin-top: 100px;
  margin-bottom: 100px;

  .inner-carusel{
    display: flex;
  }

  .item{
    min-height: 40rem;
    width: 30rem;;
    padding: 40px;

    img{
      width: 100%;
      height: 100%;
    }
  }
}


