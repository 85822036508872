@import "./../../style/index.scss";

.bg-contact{
    background-image: url('./contact-bg.jpg');
}

.contact{    
    margin: 0 20px; 

    .contact-content{
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        margin-top: 150px;
        margin-bottom: 150px;

        @include for-Extra-Small-devices {
            flex-direction: column;
            align-items: center;
        }

        @media (min-width: $Sm) and (max-width: $Lg) {
            flex-direction: column;
            align-items: center;
        }  

        a{
            font-size: 20px;
            &:hover{
                color: $gold;
            }        
        }

        .contact-data{
            display: flex;
            flex-direction: column;
            width: 40%;

            
        @include for-Extra-Small-devices {
            width: 90%;
            margin-top: 100px;
        }

        @media (min-width: $Sm) and (max-width: $Lg) {
            width: 90%;
            margin-top: 100px;
        }  
        }

        .contact-link{
            display: flex;
            align-items: center;
            margin-top: 10px;
            margin-bottom: 10px;
            justify-self: unset;
            justify-content: center;

            @media screen and (min-width: $Lg) {
                justify-content: left;
            }

            a{
                color: black;
            }

            img{
                width: 25px;
                margin-right: 30px;
            }
        }

        .form{
            width: 40%;

            @include for-Extra-Small-devices {
                width: 90%;
            }
    
            @media (min-width: $Sm) and (max-width: $Lg) {
                width: 90%;
            }  

            form{
                display: flex;
                flex-direction: column;
                
                label{
                    margin-top: 20px;
                }
    
                input, textarea{
                    margin-top: 10px;
                    margin-top: 10px;
                    border: none;
                    border-bottom: 1px solid black;

                    &:focus-visible{
                        outline: none;
                        border-bottom: 1px solid $gold;
                    }
                }
    
                .btn{
                    color: white;
                    background-color: $gold;
                    font-size: 20px;
                    border-bottom: none;
                    margin-top: 30px;
                    padding: 15px 30px;
                    font-weight: 600;
                    cursor: pointer;
                }
    
                
            }
        }

 

    }

}

