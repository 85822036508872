@import "./../../style/index.scss";

footer {
  background-image: url('../../images/footer.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 0;

.contact-link{
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 20px;
     
  img{
    margin-right: 10px;
    width: 25px;
    }

  @include for-Extra-Small-devices {
      font-size: 15px;
    }   
  }

  &::before{
    content: "";
    background-color: #00000091;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  a{
    display: block;
    font-weight: 600;
    color: white;
    font-size: 18px;    
    &:hover{      
      color: $gold;
    }

    @include for-Medium-devices {
      font-size: 14px;
      font-weight: 600;
    }
  }

  h4{
    margin-bottom: 30px;
    color: white;
    font-size: 20px;
    position: relative;

    @include for-Extra-Small-devices {
      margin-top: 30px;
    }

    @include for-Small-devices {
      font-size: 25px;
    }

    &::before{
      content: "";
      background-color: $gold;
      position: absolute;
      width: 49px;
      height: 3px;
      top: 28px;
      left: 0px;

      @include for-Small-devices {
        top: 35px;
     }
    }
  }

  h3{
    font-size: 18px;
    font-weight: 600;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .footer-column{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 50px 10px;

    @include for-Extra-Small-devices {
      flex-direction: column;
    }

    @include for-Small-devices {
      flex-direction: column;
    }

    .column{
      display: flex;

      @include for-Extra-Small-devices {      
        justify-content: center;
      }

      @include for-Small-devices {      
        justify-content: center;
      }

      .inner-container{
        display: inline-flex;
        flex-direction: column;


        @include for-Extra-Small-devices {   
          width: 60vw;       
          
          h4{
            display: block;
          }
          .contact-link{
            display: inline-flex;
          }
        }

        @include for-Small-devices {      
          flex-direction: column;         
          margin-top: 15px;
          width: 45vw;
          }
      }
    }
  }
}
