@import '../../../style/index.scss';


#gallery{
  margin-top: 150px;
  margin-bottom: 150px;
}

.galleryWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
  max-width: 620px;
  margin: 0 auto;

  .single {
    width: 200px;
    cursor: pointer;

    img {
      max-width: 100%;

      &:hover {
        transform: scale(1.02);
      }
    }
  }
}

.sliderWrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .btnClose, .btnPrev, .btnNext {
    position: fixed;
    cursor: pointer;
    opacity: 0.6;
    color: #fff;
    z-index: 9999;
  }
}

.btnNext:hover, .btnPrev:hover, .btnClose:hover {
  color: $gold;
}

.sliderWrap {
  .btnClose {
    top: 40px;
    right: 40px;
  }

  .btnPrev {
    top: 50%;
    transform: translateY(-50%);
    left: 40px;
  }

  .btnNext {
    top: 50%;
    transform: translateY(-50%);
    right: 40px;
  }
}

.fullScreenImage {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 100%;
    pointer-events: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}