$Sm: 576px;
$Md: 768px;
$Lg: 992px;
$Xl: 1200px;
$Xxl: 1400px;


$gold: #fcd100;
$gold2: #fcd100d6;
$green: #000000eb;
$green2 : #000000ab;

